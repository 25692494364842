import './style.css'
import * as dat from 'lil-gui'
import * as THREE from 'three'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { Vector3 } from 'three'
import { gsap } from 'gsap'
import particlesVertexShader from './shaders/vertex.glsl'
import particlesFragmentShader from './shaders/fragment.glsl'

import countryByName from './json-datas/country-by-name.json'
import countryByAbreviation from './json-datas/country-by-abbreviation.json'
import countryByAvgMalHeight from './json-datas/country-by-avg-male-height.json'
import countryByBarcode from './json-datas/country-by-barcode-prefix.json'
import countryByCallingCode from './json-datas/country-by-calling-code.json'
import countryByCapitalCity from './json-datas/country-by-capital-city.json'
import countryByContinent from './json-datas/country-by-continent.json'
import countryByCurrenyCode from './json-datas/country-by-currency-code.json'
import countryByCurrencyName from './json-datas/country-by-currency-name.json'
import countryByDomain from './json-datas/country-by-domain-tld.json'
import countryByElevation from './json-datas/country-by-elevation.json'
import countryByGeoCoordinates from './json-datas/country-by-geo-coordinates.json'
import countryByGovernmentType from './json-datas/country-by-government-type.json'
import countryByIndependenceDate from './json-datas/country-by-independence-date'
import countryByISONumeric from './json-datas/country-by-iso-numeric'
import countryByLandLocked from './json-datas/country-by-landlocked'
import countryByLanguages from './json-datas/country-by-languages'
import countryByLifeExpectancy from './json-datas/country-by-life-expectancy'
import countryByNationalDish from './json-datas/country-by-national-dish'
import countryByNationalSymbol from './json-datas/country-by-national-symbol.json'
import countryByPopulationDensity from './json-datas/country-by-population-density'
import countryByPopulation from './json-datas/country-by-population'
import countryByRegionInWorld from './json-datas/country-by-region-in-world.json' 
import countryByReligion from './json-datas/country-by-religion'
import countryBySurfaceArea from './json-datas/country-by-surface-area'
import countryByYearlyAverageTemperature from './json-datas/country-by-yearly-average-temperature'
// console.log('countryByNationalDish', countryByNationalDish)
// sounds
// ambienceSound
const ambienceSound = new Audio('/sounds/ambient.mp3')
// ambienceSound.loop = true
ambienceSound.addEventListener('ended', ()=> {
    ambienceSound.currentTime = 0;
    ambienceSound.play();
}, false);

const playAmbienceSound = ()=>{
	ambienceSound.currentTime = 0
	ambienceSound.play()
}
// earthquakeSound
const earthquakeSound = new Audio('/sounds/earthquake.mp3')
const playearthquakeSound = ()=>{
	earthquakeSound.currentTime = 0
	earthquakeSound.play()
}
// earthquakeSoundBack
const earthquakeSoundBack = new Audio('/sounds/earthquake-back.mp3')
 const playearthquakeBackSound = ()=>{
	earthquakeSoundBack.currentTime = 0
	earthquakeSoundBack.play()
}

 
let audioStatusActive = true; 
// stop audio
const noteIconButton = document.querySelector('.noteIcon_button')
noteIconButton.addEventListener('click',()=>{
	noteIconButton.classList.toggle('stoped')
	if(noteIconButton.classList.contains('stoped')){
		ambienceSound.pause()
		audioStatusActive = false
	} else {
		ambienceSound.play()
		audioStatusActive = true
	}
})


//burger menu event toggle active 
const menuWrapper = document.querySelector('.menu-wrapper')
const burggerMenuButton = document.querySelector('.burger_menu-container .burgerButton')
burggerMenuButton.addEventListener('click',()=>{
	menuWrapper.classList.toggle('active')
})

const closeMenuIcon = document.querySelector('.closeMenuIcon')
closeMenuIcon.addEventListener('click',()=>{
	menuWrapper.classList.toggle('active')
})


const noteIconActive = document.querySelector('.noteIcon_container')
noteIconActive.addEventListener('click',()=>{
	noteIconActive.classList.toggle('active')
})

//popup ratio
let poppedActive

function preloader(){
	if(!localStorage.getItem('entry')){
		poppedActive = true
		localStorage.setItem('entry', true)
	} else{
		poppedActive = false
	}
	// poppedActive = true
}
preloader()

let isLandscape 

const hintsPopupWrapper = document.querySelector('.hints-popup-wrapper')
const hintsPopupContainer = document.querySelector('.hints-popup-container')
//loaders
const previewWrapper = document.querySelector('.preview-wrapper')
const exploreButton = document.querySelector('button.explore')
const loadingManager = new THREE.LoadingManager(
	//loaded
	()=>{
		exploreButton.style.display ='inline-block'
		window.setTimeout(()=>{
			exploreButton.style.opacity = 1

			
		},500)
	},
	//progress
	(itemUrl, itemsLoaded, itemsTotal)=>{
		// console.log('itemUrl', itemUrl)
		// console.log('itemsTotal', itemsTotal)
		// console.log('progressRatio', progressRatio)
		const progressRatio = itemsLoaded / itemsTotal
		if(itemsLoaded === 1){
			
		}
	}

)
let hintsActive 
if(poppedActive){
	hintsActive = true
}
function closePopup(){
	hintsActive = false
	hintsPopupContainer.classList.remove('popped')
	hintsPopupWrapper.classList.remove('popped')
	hintsPopupWrapper.style.zIndex = 100
	window.setTimeout(()=>{
		hintsPopupWrapper.classList.add('hidden')
	}, 450)
}


//menuInstructionButton from menu
const menuInstructionButton = document.querySelector('.menuInstructionButton')
menuInstructionButton.addEventListener('click', ()=>{
	menuWrapper.classList.toggle('active')
	slideCounter = 1
	iNeedHintsFunction()	
	hintsActive = true
})

const iNeedHintsButton = document.querySelector('.buttons-container button.yes')
const buttonsContainer = document.querySelector('.buttons-container button.no')
const closePopupButton = document.querySelector('.closePopup')
const voidFormContainerInHints = document.querySelector('.voidFormContainer.inHints')
closePopupButton.addEventListener('click',()=>{
	closePopup()
})
buttonsContainer.addEventListener('click',()=>{
	closePopup()
})
iNeedHintsButton.addEventListener('click',()=>{
	closePopup()
	iNeedHintsFunction()	
	hintsActive = true
})

const zoomWrapper = document.querySelector('.zoom_wrapper')
const countrySearcherWrapper = document.querySelector('.country-searcher_wrapper')
const zoomRangeWrapper = document.querySelector('.zoomRange_wrapper')
exploreButton.addEventListener('click',()=>{

	// raycasterActive = false
	previewWrapper.classList.add('fade-out')
	countrySearcherWrapper.classList.add('fade-in')
	zoomWrapper.classList.add('fade-in')
	zoomRangeWrapper.classList.add('fade-in')
	// gsap.to(camera.position,{
	// 		y: 8.7,
	// 		duration: 1.6,
	// 		onComplete: function(){
	// 			raycasterActive = true
	// 			window.setTimeout(()=>{
	// 				hintsPopupContainer.classList.add('popped')
	// 				hintsPopupWrapper.classList.add('popped')
	// 			},100)
	// 		}
	// })
	window.setTimeout(()=>{
		previewWrapper.classList.add('hidden')
		if(poppedActive && !isLandscape){
			hintsPopupContainer.classList.add('popped')
			hintsPopupWrapper.classList.add('popped')
		}
		if(audioStatusActive){
			playAmbienceSound()
		}
	}, 1000)


})

//input 
const countrySearcher = document.querySelector('.country-searcher')
const countrySearcherContainer = document.querySelector('.country-searcher_wrapper')
const countryName = document.querySelector('.countryName')
const languagesHtml = document.querySelector('.languages_container')


//hints

let iNeedHints = false;
let slideCounter = 1;

const closeHints = document.querySelector('.closeHints')
const hintsWrapper = document.querySelector('.hints-wrapper')

const hintOneFullscreen = document.querySelector('.hintOne_fullscreen')
const hintTwoRangeBar = document.querySelector('.hintTwo_rangeBar')
const hintThreeZoomInOut = document.querySelector('.hintThree_zoomInOut')
const hintFourCountryClick = document.querySelector('.hintFour_countryClick')
const hintFiveCountrySearcher = document.querySelector('.hintFive_countrySearcher')
const locationButton = document.querySelector('.locationButton button')

const hintsHeader = document.querySelector('.hints-wrapper h1')
const zoomContainer = document.querySelector('.zoom_container')
const screenStatus = document.querySelector('.screenStatus')
const sliderCount = document.querySelectorAll('.slider-count .count')
const noteIconContainer = document.querySelector('.noteIcon_container')
//fullscren hint function
function hintOne(){
	hintsWrapper.style.opacity = 1
	locationButton.classList.add('hidden')
	hintOneFullscreen.classList.remove('hidden')
	hintTwoRangeBar.classList.add('hidden')	
	hintThreeZoomInOut.classList.add('hidden')	
	hintFourCountryClick.classList.add('hidden')	
	hintFiveCountrySearcher.classList.add('hidden')	
	burggerMenuButton.classList.add('hidden')

	hintsHeader.classList.remove('hidden')
	countrySearcherWrapper.classList.add('hidden')
	zoomRangeWrapper.classList.add('hidden')
	zoomContainer.style.display = 'none'	
	screenStatus.style.display = 'flex'

}

function hintTwo(){
	locationButton.classList.add('hidden')
	hintOneFullscreen.classList.add('hidden')
	hintTwoRangeBar.classList.remove('hidden')	
	hintThreeZoomInOut.classList.add('hidden')	
	hintFourCountryClick.classList.add('hidden')	
	hintFiveCountrySearcher.classList.add('hidden')	
	burggerMenuButton.classList.add('hidden')


	countrySearcherWrapper.classList.add('hidden')
	hintsHeader.classList.remove('hidden')
	zoomRangeWrapper.classList.remove('hidden')
	zoomContainer.style.display = 'none'
	screenStatus.style.display = 'none'
}
function hintThree(){
	locationButton.classList.add('hidden')
	hintOneFullscreen.classList.add('hidden')
	hintTwoRangeBar.classList.add('hidden')	
	hintThreeZoomInOut.classList.remove('hidden')	
	hintFourCountryClick.classList.add('hidden')	
	hintFiveCountrySearcher.classList.add('hidden')	
	burggerMenuButton.classList.add('hidden')


	countrySearcherWrapper.classList.add('hidden')
	hintsHeader.classList.remove('hidden')
	zoomRangeWrapper.classList.add('hidden')
	zoomContainer.style.display = 'flex'
	screenStatus.style.display = 'none'
}
function hintFour(){
	locationButton.classList.remove('hidden')
	hintOneFullscreen.classList.add('hidden')
	hintTwoRangeBar.classList.add('hidden')	
	hintThreeZoomInOut.classList.add('hidden')	
	hintFourCountryClick.classList.remove('hidden')	
	hintFiveCountrySearcher.classList.add('hidden')	
	burggerMenuButton.classList.add('hidden')


	countrySearcherWrapper.classList.add('hidden')
	zoomRangeWrapper.classList.add('hidden')
	hintsHeader.classList.remove('hidden')
	zoomContainer.style.display = 'none'
	screenStatus.style.display = 'none'
}
function hintFive(){
	locationButton.classList.add('hidden')
	hintOneFullscreen.classList.add('hidden')
	hintTwoRangeBar.classList.add('hidden')	
	hintThreeZoomInOut.classList.add('hidden')	
	hintFourCountryClick.classList.add('hidden')	
	hintFiveCountrySearcher.classList.remove('hidden')	
	burggerMenuButton.classList.add('hidden')


	zoomRangeWrapper.classList.add('hidden')
	countrySearcherWrapper.classList.remove('hidden')
	hintsHeader.classList.add('hidden')
	zoomContainer.style.display = 'none'
	screenStatus.style.display = 'none'
}

function closeHintsSection(){
	zoomRangeWrapper.classList.remove('hidden')
	countrySearcherWrapper.classList.remove('hidden')
	hintsProgressContainer.classList.add('hidden')
	burggerMenuButton.classList.remove('hidden')

	zoomContainer.style.display = 'flex'
	screenStatus.style.display = 'flex'
	
	locationButton.classList.remove('hidden')
	zoomContainer.classList.remove('hinted')
	zoomWrapper.classList.remove('hinted')
	hintsWrapper.style.opacity = 0
	if(audioStatusActive){
		gsap.to(ambienceSound, {
			duration: 2,
			volume: 1,
		});
	}
	window.setTimeout(()=>{
		hintsWrapper.classList.add('hidden')
	}, 900)
	if(window.innerWidth < 1001){
		raycasterActive = false
	} else{
		raycasterActive = true
	}

	hintsActive = false
}
const hints = {
	1: hintOne,
	2: hintTwo,
	3: hintThree,
	4: hintFour,
	5: hintFive,
}

const hintsProgressContainer = document.querySelector('.hintsProgress-container')
closeHints.addEventListener('click', ()=>{
	
	
	closeHintsSection()
})
const sliderNextButton = document.querySelector('.nextButton')
const sliderPrevButton = document.querySelector('.prevButton')

sliderPrevButton.addEventListener('click',()=>{
	if(slideCounter === 1){
		return;
	}else{
		slideCounter--;
		hints[slideCounter]()
		sliderCount.forEach((count)=>{
			count.textContent = slideCounter
		})
	}

	// console.log('slideCounter', slideCounter)
})
sliderNextButton.addEventListener('click',()=>{
	if(slideCounter === 5){
		closeHintsSection();
		return;
	}else{
		slideCounter++;
		hints[slideCounter]()
		sliderCount.forEach((count)=>{
			count.textContent = slideCounter
		})
	}
	
	// console.log('slideCounter', slideCounter)
})

const countryClickInfoContainerForPc = document.querySelector('.countryClick-info-container .forPc')
const countryClickInfoContainerForMob = document.querySelector('.countryClick-info-container .forMob')

const hintFourImgForPc = document.querySelector('.hintFour-img.forPc img')
const hintFourImgForMob = document.querySelector('.hintFour-img.forMob img')

const sliderCountForPc = document.querySelector('.slider-count.forPc')
const sliderCountForMob = document.querySelector('.slider-count.forMob')

function iNeedHintsFunction(){
	hintsWrapper.classList.remove('hidden')
	hintsProgressContainer.classList.remove('hidden')
	if(audioStatusActive){
			gsap.to(ambienceSound, {
				duration: 2,
				volume: 0.2,
				});
		}

	hints[slideCounter]()
	sliderCount.forEach((count)=>{
		count.textContent = slideCounter
	})


	//phone migration
	if(window.innerWidth > 1001){
		countryClickInfoContainerForMob.classList.add('hidden')
		countryClickInfoContainerForPc.classList.remove('hidden')

		hintFourImgForMob.classList.add('hidden')
		hintFourImgForPc.classList.remove('hidden')

		
		sliderCountForMob.classList.add('hidden')
		sliderCountForPc.classList.remove('hidden')
		
		zoomContainer.classList.remove('hinted')
		zoomWrapper.classList.remove('hinted')


	}else{
		countryClickInfoContainerForPc.classList.add('hidden')
		countryClickInfoContainerForMob.classList.remove('hidden')

		hintFourImgForPc.classList.add('hidden')
		hintFourImgForMob.classList.remove('hidden')

		sliderCountForPc.classList.add('hidden')
		sliderCountForMob.classList.remove('hidden')

		zoomContainer.classList.add('hinted')
		zoomWrapper.classList.add('hinted')

	}
	if(currentIntersected){

		const currentItersectedMaterial = currentIntersected.material.clone()
		currentItersectedMaterial.color = {r:1, g: 1, b: 1}
		currentItersectedMaterial.blending = 1
		currentIntersected.material = currentItersectedMaterial
	}
	raycasterActive = false
}








//find the cpuntry functionallity
const findTheCountry = (countryBy, countryName, htmlInput, property)=>{
	const dataResult = countryBy.find(country => country.country === countryName)
	if(dataResult){
		const {[property]: name} = dataResult
		if(name === null){
			htmlInput.innerHTML = 'No information'
		}else{
			htmlInput.innerHTML = name
			// console.log('name', name)
		}

	}else{
		htmlInput.innerHTML = 'No information'
	}
}

const countryFinderByClick =(currentIntersected)=>{
	//retrive data
				const countryAbbreviation = currentIntersected.name.slice(0,2)
				const countryNameSlicedData = countryByAbreviation.find(country => country.abbreviation === countryAbbreviation).country
				countryName.innerHTML = countryNameSlicedData
				// Country Abbreviation
				const countryAbbreviationHtml = document.querySelector('.country-abbreviation')
				countryAbbreviationHtml.innerHTML = `(${countryAbbreviation})`
				// Capital City
				const capitalCityHtml = document.querySelector('.city')
				findTheCountry(countryByCapitalCity, countryNameSlicedData, capitalCityHtml, "city")
				// Continent
				const continentHtml = document.querySelector('.continent')
				findTheCountry(countryByContinent, countryNameSlicedData, continentHtml, "continent")
				//Region In World
				const regionHtml = document.querySelector('.region')
				findTheCountry(countryByRegionInWorld, countryNameSlicedData, regionHtml, "location")
				//Geographic Coordinates
				// Latitude
				const northLatitudeHtml = document.querySelector('.north-coordinates')
				findTheCountry(countryByGeoCoordinates, countryNameSlicedData, northLatitudeHtml, "north")
				const southLatitudeHtml = document.querySelector('.south-coordinates')
				findTheCountry(countryByGeoCoordinates, countryNameSlicedData, southLatitudeHtml, "south")
				//Longitude
				const westLongitudeHtml = document.querySelector('.west-coordinates')
				findTheCountry(countryByGeoCoordinates, countryNameSlicedData, westLongitudeHtml, "west")
				const eastLongitudeHtml = document.querySelector('.east-coordinates')
				findTheCountry(countryByGeoCoordinates, countryNameSlicedData, eastLongitudeHtml, "east")
				//geographic condition
				//surface area
				const surfaceAreaHtml = document.querySelector('.area')
				findTheCountry(countryBySurfaceArea, countryNameSlicedData, surfaceAreaHtml, "area")
				//elevation
				const elevationHtml = document.querySelector('.elevation')
				findTheCountry(countryByElevation, countryNameSlicedData, elevationHtml, "elevation")
				//land locked
				const landLockedHtml = document.querySelector('.land-locked')				
				const landLockedSlicedData = countryByLandLocked.find(country => country.country === countryNameSlicedData)
				if(landLockedSlicedData){
					switch(landLockedSlicedData.landlocked){
						case '0':
							landLockedHtml.innerHTML = 'Surrounded by watter';
							break;
							case '1':
								landLockedHtml.innerHTML = 'Not surrounded by watter';
								break;
							default:
								landLockedHtml.innerHTML = 'No information';
							}				
					}else{
						landLockedHtml.innerHTML = 'No information';
					}
				//Yearly temperature
				const yearlyTemperatureHtml = document.querySelector('.temperature')
				findTheCountry(countryByYearlyAverageTemperature, countryNameSlicedData, yearlyTemperatureHtml, "temperature")
				//Population
				//population
				const populationHtml = document.querySelector('.population')
				findTheCountry(countryByPopulation, countryNameSlicedData, populationHtml, "population")
				//population density
				const populationDensityHtml = document.querySelector('.density')
				const populationDensitySlicedData = countryByPopulationDensity.find(country => country.country === countryNameSlicedData)
				if(populationDensitySlicedData){
					populationDensityHtml.innerHTML = populationDensitySlicedData.density.toFixed()
				}else{
					populationDensityHtml.innerHTML = 'No information'
				}
				//life expentancy
				const lifeExpectancyHtml = document.querySelector('.life-expectancy')
				findTheCountry(countryByLifeExpectancy, countryNameSlicedData, lifeExpectancyHtml, "expectancy")
				//languages
				const verifingLanguagesSlicedData = countryByLanguages.find(country => country.country === countryNameSlicedData)
				if(verifingLanguagesSlicedData){
					const languagesSlicedData = verifingLanguagesSlicedData.languages
					if(languagesSlicedData){
						const languagesGridName = document.querySelector('.languages_container .grid-name')
						languagesGridName.style.setProperty('--languages', languagesSlicedData.length + 1 )
		
						for(let i = 0; i < languagesSlicedData.length; i++){
							languagesHtml.innerHTML += `<div class="grid-line"></div>`
							languagesHtml.innerHTML += `<div class="languages grid-data">${languagesSlicedData[i]}</div>`
						}	
					} else{
						languagesHtml.innerHTML += `<div class="grid-line"></div>`
						languagesHtml.innerHTML += `<div class="languages grid-data">No information</div>`
					}

				}
				//Culture
				//religion
				const religionHtml = document.querySelector('.religion')
				findTheCountry(countryByReligion, countryNameSlicedData, religionHtml, "religion")
				//national dish
				const nationalDishHtml = document.querySelector('.national-dish')
				findTheCountry(countryByNationalDish, countryNameSlicedData, nationalDishHtml, "dish")
				//national symbol
				const nationalSymbolHtml = document.querySelector('.national-symbol')
				findTheCountry(countryByNationalSymbol, countryNameSlicedData, nationalSymbolHtml, "symbol")
				//other information
				//government type
				const governmentTypeHtml = document.querySelector('.government-type') 
				findTheCountry(countryByGovernmentType, countryNameSlicedData, governmentTypeHtml, "government")
				//independence day
				const independenceDayHtml = document.querySelector('.independence-day') 
				findTheCountry(countryByIndependenceDate, countryNameSlicedData, independenceDayHtml, "independence")
				//currency name
				const currencyNameHtml = document.querySelector('.currency-name') 
				findTheCountry(countryByCurrencyName, countryNameSlicedData, currencyNameHtml, "currency_name")
				//currency code
				const currencyCodeHtml = document.querySelector('.currency-code')
				findTheCountry(countryByCurrenyCode, countryNameSlicedData, currencyCodeHtml, "currency_code")
				//male height
				const maleHeightHtml = document.querySelector('.male-height')
				findTheCountry(countryByAvgMalHeight, countryNameSlicedData, maleHeightHtml, "height")
				//calling code
				const callingCodeHtml = document.querySelector('.code')
				findTheCountry(countryByCallingCode, countryNameSlicedData, callingCodeHtml, "calling_code")
				//iso numeric
				const isoNumericHtml = document.querySelector('.iso-numeric')
				findTheCountry(countryByISONumeric, countryNameSlicedData, isoNumericHtml, "iso")
				//domain
				const domainHtml = document.querySelector('.domain')
				findTheCountry(countryByDomain, countryNameSlicedData, domainHtml, "tld")
				//barcode 
				const barcodeHtml = document.querySelector('.barcode')
				findTheCountry(countryByBarcode, countryNameSlicedData, barcodeHtml, "barcode")
				//flag
				const flagHtml = document.querySelector('.flag')
				const imgFlag = document.createElement("img")
				const lowerAbv = countryAbbreviation.toLowerCase()
				imgFlag.src = require(`./flag/${lowerAbv}.png`)
				flagHtml.innerHTML = ''
				flagHtml.appendChild(imgFlag) 

				// console.log('countryStatsInfo.target.scrollTop', countryStatsInfo.scrollTop)
				
 
}
/**
 * Base
 */
// Debug
const active = window.location.hash === '#debug'
if(active)
{
	var gui =  new dat.GUI({
		width: 400
		})
}
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager)

// Draco loader
const dracoLoader = new DRACOLoader(loadingManager)
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)

//baked texture
const bakedTexture = textureLoader.load('chocolate.jpg')
bakedTexture.flipY = false
bakedTexture.encoding = THREE.sRGBEncoding

// baked material
const bakedMaterial = new THREE.MeshBasicMaterial({map: bakedTexture})

//models to test
const modelsToTest = []
var loadingStatus = false
// console.log('loadingStatus', loadingStatus)

//imported scene
let gltfScene = {}
let arrayOfAllCountries = []
let arrayOfAvailableCountries = []
//model
gltfLoader.load(
	'mapwithorigin.glb',
	(gltf)=>{
		gltf.scene.traverse((child)=>{
			child.material = bakedMaterial
		})
		scene.add(gltf.scene)
		loadingStatus = true
		const gltfMeshes = gltf.scene.children
		modelsToTest.push(...gltfMeshes)
		gltfScene = {...gltf.scene}

		// console.log('gltf.scene.children', gltf.scene.children)
		gltf.scene.children.forEach((sceneChildren)=>{
			if(sceneChildren.name !== 'Plane'){
			const sceneAbbreviation = sceneChildren.name.slice(0,2)
			// console.log('sceneAbbreviation', sceneAbbreviation)
			const countryName = countryByAbreviation.filter(countryAbv => countryAbv.abbreviation === sceneAbbreviation)[0].country
			// console.log('countryName', countryName, sceneAbbreviation)
			arrayOfAvailableCountries.push(countryName)
			// console.log('sceneAbbreviation', sceneAbbreviation)
			// if(childrenAbv.abbreviation === )
			}
		})
		// console.log('arrayOfAvailableCountries', arrayOfAvailableCountries)
		let difference = countryByName.filter(x => !arrayOfAvailableCountries.includes(x.country))
		// console.log('difference', difference)
	}
)

//raycaster
let raycasterActive = true
const raycaster = new THREE.Raycaster()

/**
 * Sizes 
 */
// for mobile devices
const sizes = {
	width: window.innerWidth ,
	height: window.innerHeight

}
// if(sizes.width < 801){
// 	sizes.height = window.innerHeight * 0.95
// }else{
// 	sizes.height = window.innerHeight

// }

//mouse
const mouse = new THREE.Vector2()
canvas.addEventListener('mousemove', (event)=>{
	mouse.x = event.clientX / sizes.width * 2 - 1
		mouse.y = -(event.clientY / sizes.height) * 2 + 1 

	// if(sizes.width < 801){
	// 	mouse.y = -(event.clientY / sizes.height) * 2 + 1 + 0.13
	// } else{
	// 	mouse.y = -(event.clientY / sizes.height) * 2 + 1
	// }
	// console.log('event', event.clientY)
	// console.log('mouse.x', mouse.x)
	// console.log('mouse.y', mouse.y)
})
	// console.log('canvas', canvas)

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(55, sizes.width / sizes.height, 0.1, 100)
const cameraDebug ={
	x:0,
	y: 9,
	z:2.33,
}
// camera.rotation.set(1, 0, 0)
if(loadingStatus === true){
	gltfScene.position.z = cameraDebug.z
}
camera.position.x = cameraDebug.x
camera.position.y =  cameraDebug.y
camera.position.z =  cameraDebug.z
scene.add(camera)
if(active)
{
	gui.add(cameraDebug, 'x', 0, 10, 0.01).onChange(()=>{
		camera.position.x = cameraDebug.x
	})
	gui.add(cameraDebug, 'z', -10, 10, 0.01).onChange(()=>{
		camera.position.z = cameraDebug.z
		gltfScene.position.z = cameraDebug.z
	
	})
	gui.add(cameraDebug, 'y', 0, 10, 0.01).onChange(()=>{
		camera.position.y =  cameraDebug.y
	
	})
}

	const countryCardTemplate = document.querySelector("[data-country-template]")
   const voidContainer = document.querySelector('.void-container')
	const countrySearcherResultsContainer = document.querySelector('.country-searcher-results_container')
	function leavingSearcherForm(){
		if(sizes.width < 1001){
			zoomWrapper.classList.remove('hidden')
		}
		countrySearcherResultsContainer.classList.add('hidden')
		voidContainer.classList.add('hidden')
		rangeZoom.classList.remove('hidden')
		removeValue.classList.add('hidden')
		voidFormContainer.classList.remove('hidden')

		raycasterActive = true
		controls.enablePan = true

	}
	voidContainer.addEventListener('click',()=>{

		leavingSearcherForm()
		formIsActive = false

	})
	let countriesByInput = []
	let visibleCountriesAfterInput = []
	let formIsActive

	function inputField(e){
		raycasterActive = false
		formIsActive = true
		//neutralize the current intersected country material 
		if(currentIntersected){
			const currentItersectedMaterial = currentIntersected.material.clone()
			currentItersectedMaterial.color = {r:1, g: 1, b: 1}
			currentItersectedMaterial.blending = 1
			currentIntersected.material = currentItersectedMaterial
		}
		
		if(sizes.width < 1001){
			zoomWrapper.classList.add('hidden')
			rangeZoom.classList.add('hidden')
		}
		countrySearcherResultsContainer.classList.remove('hidden')
		voidContainer.classList.remove('hidden')
		removeValue.classList.remove('hidden')
		voidFormContainer.classList.add('hidden')


		let value;
		if(e){
			value = e.target.value.toLowerCase()
		}else{
			value = ''
		}
		// let countCountryBoolean = false 
		countriesByInput.forEach(country => {
			const isVisible = country.name.toLowerCase().includes(value)
			country.element.classList.toggle("hidden", !isVisible)
			
			// console.log('countriesByInput', countriesByInput)
		})
	}

	countrySearcher.addEventListener('input', (e)=>{

		inputField(e);

	})


	countriesByInput = countryByName.map((country)=>{
		// console.log('country', country)
		const card = countryCardTemplate.content.cloneNode(true)
		const body = card.querySelector("[data-body]")
		body.textContent = country.country
		body.classList.add('hidden')
		body.setAttribute("id", country.country)
		countrySearcherResultsContainer.append(card)
		// console.log('body', body)
		return {name: country.country, element: body}
	})



	const voidFormContainer = document.querySelector('.voidFormContainer')
	voidFormContainer.addEventListener('click',()=>{
		if(hintsActive){
			return;
		}
		
		countrySearcher.value = ''
		countrySearcher.focus()
		countrySearcherResultsContainer.scrollTop = 0
		
		// const e = countrySearcher.va
		inputField();
		removeValue.classList.remove('hidden')
		voidFormContainer.classList.add('hidden')
		if(sizes.width < 1001){
			zoomWrapper.classList.add('hidden')
			rangeZoom.classList.add('hidden')
		}

	})

	const removeValue = document.querySelector('.removeValue')

	removeValue.addEventListener('click',()=>{
		leavingSearcherForm()
		formIsActive = false
	})


let previousCameraY;
	
// HTML Elements
	//info beta
	let currentIntersected;
	let currentCountry = false; 
	let currentCountryPositionY; 
	let timer = 0;
	let timerInterval;

	let currentPositionCamera = new Vector3(); 
	currentPositionCamera.x = 0
	currentPositionCamera.y = 8.71275548278095
	currentPositionCamera.z = 2.255635586097737

	let newPositionCamera = new Vector3();
	let endEventType;
	let cameraDinamic = {
		x: 0,
		y: 0,
		z: 0,
	};
	const countryStatsWrapper = document.querySelector('.countryStats_wrapper')
	const countryStatsVisualization = document.querySelector('.countryStats-visualization')
	const closeIcon = document.querySelector('.closeIcon')
	const countryStatsInfo = document.querySelector('.countryStats-info')
	let secondCurrentCountryPositionY
	
	closeIcon.addEventListener('click',()=>{
		particlesMaterial.uniforms.animation.value = false
		if(sizes.width > 1001){
			fragmentShaderOpacityDecrease()
		}

		if(audioStatusActive){
			gsap.to(ambienceSound, {
				duration: 2,
				volume: 1,
				});
		}
		secondCurrentCountryPositionY = currentCountryPositionY
		raycasterActive = false
		controls.enabled = false 
		controls.enablePan = true

		animationInProcess = false
		formIsActive = false
		zoomWrapper.classList.remove('noControls')

		searchedCountryBool = false
		countrySearcherContainer.classList.remove('hidden')
		countryStatsWrapper.classList.add('hidden')
		languagesHtml.innerHTML = `<div class="grid-name">Languages</div>`
		gsap.to(currentCountry.position, {
			y: secondCurrentCountryPositionY,
			duration: 2,
			onStart:function(){
				if(audioStatusActive){
					playearthquakeBackSound()
				}
			}
		})

		if(previousCameraY < 4.4 ){
			// console.log('firse')
			gsap.to(gltfScene.position, {
				x: camera.position.x - currentCountry.position.x,
				z: camera.position.z  - 1.6 - currentCountry.position.z ,
				duration: 1.5,
				onUpdate:function(){

					particles.position.x = gltfScene.position.x
					particles.position.z = gltfScene.position.z
				},
				

			})	
			gsap.to(particles.position, {
				x: camera.position.x - currentCountry.position.x,
				z: camera.position.z  - 1.6 - currentCountry.position.z ,
				duration: 1.5,
			})	

		} else if(previousCameraY > 4.4 && previousCameraY < 7.60){
			// console.log('second')

			gsap.to(gltfScene.position, {
				x: camera.position.x - currentCountry.position.x,
				z: camera.position.z  - 2.52 - currentCountry.position.z ,
				duration: 1.5,
			})	
			gsap.to(particles.position, {
				x: camera.position.x - currentCountry.position.x,
				z: camera.position.z  - 2.52 - currentCountry.position.z ,
				duration: 1.5,
			})	
		}else{
			gsap.to(gltfScene.position, {
				x: camera.position.x - currentCountry.position.x,
				z: camera.position.z  - 1.5 - currentCountry.position.z ,
				duration: 1.5,
			})
			gsap.to(particles.position, {
				x: camera.position.x - currentCountry.position.x,
				z: camera.position.z  - 1.5 - currentCountry.position.z ,
				duration: 1.5,
			})
		}

		
		gsap.to(camera.position,{
			x: cameraDinamic.x,
			y: cameraDinamic.y,
			z: cameraDinamic.z,
			// z: camera.position.z - 0.8,
			duration: 1.5,
			onComplete: function(){
				controlsVoid.classList.add('hidden')

				controls.enabled = true 
				if(sizes.width < 1001){
					raycasterActive = false
				} else{
					raycasterActive = true
				}
				particlesMaterial.uniforms.animation.value = true
					fragmentShaderOpacityIncrease()
					// shadersOpacityIncrease = true
					

			}
		}
		
		)

		gsap.to(camera,{
			fov: cameraFov,
			duration: 1.5,
			onUpdate: function(){
				camera.updateProjectionMatrix();
			},
			onComplete: function(){
				//neutralize the country material

				if(searchedCountry){
					// const searchedCountryMaterial = searchedCountry.material.clone()
					// searchedCountryMaterial.color = {r:1, g: 1, b: 1}
					// searchedCountryMaterial.blending = 1
					// searchedCountry.material = searchedCountryMaterial
					
					currentCountry = false
				} else{
					currentCountry = false

				}
				
				countryStatsInfo.scrollTop = 0
				if(inspectedWithMouse){
					raycasterActive = true
				}
			}
		})
		countrySearcherResultsContainer.classList.add('hidden')
		voidContainer.classList.add('hidden')
		removeValue.classList.add('hidden')
		voidFormContainer.classList.remove('hidden')

		rangeZoom.classList.remove('hidden')
		zoomWrapper.classList.remove('hidden')

		countrySearcher.style.backgroundPosition = '-30rem'
		closeIcon.classList.add('delayed')
	
	})
	let shaderFragmentInterval;

	function fragmentShaderOpacityDecrease(){
		clearInterval(shaderFragmentInterval)
		shaderFragmentInterval = setInterval(()=>{
		particlesMaterial.uniforms.uOpacity.value -= 0.1 
		// console.log('stillDecreasing')
		if(particlesMaterial.uniforms.uOpacity.value <= 0){
			clearInterval(shaderFragmentInterval)

		}
	},100) 
	}

	function fragmentShaderOpacityIncrease(){
		clearInterval(shaderFragmentInterval)
		shaderFragmentInterval = setInterval(()=>{
		particlesMaterial.uniforms.uOpacity.value += 0.1 
		// console.log('stillIncreasing')
		if(particlesMaterial.uniforms.uOpacity.value >= 1){
			clearInterval(shaderFragmentInterval)
		}
	},100) 
	}
const cameraMovement = (currentIntersected)=>{
  previousCameraY = camera.position.y
	cameraDinamic.x = camera.position.x
	cameraDinamic.y = camera.position.y
	cameraDinamic.z = camera.position.z
	particlesMaterial.uniforms.animation.value = false
	fragmentShaderOpacityDecrease()
	if(audioStatusActive){
		gsap.to(ambienceSound,{
			volume: 0.2,
			duration: 3,
		})
	}
	currentCountryPositionY = currentIntersected.position.y
	currentCountry = currentIntersected
	if(sizes.width > 1100){
		gsap.to(gltfScene.position, {
			x: camera.position.x - currentIntersected.position.x + 1,
			z: camera.position.z - 2 - currentIntersected.position.z ,
			duration: 1.5,
		})
		gsap.to(particles.position, {
			x: camera.position.x - currentIntersected.position.x + 1,
			z: camera.position.z - 2 - currentIntersected.position.z ,
			duration: 1.5,
		})
	} else if(sizes.width < 1001){
		gsap.to(gltfScene.position, {
			x: camera.position.x - currentIntersected.position.x,
			z: camera.position.z - 2 - currentIntersected.position.z ,
			duration: 1,
		})
		gsap.to(particles.position, {
			x: camera.position.x - currentIntersected.position.x,
			z: camera.position.z - 2 - currentIntersected.position.z ,
			duration: 1,
		})
	} else {
		gsap.to(gltfScene.position, {
			x: camera.position.x - currentIntersected.position.x + 0.6,
			z: camera.position.z - 2 - currentIntersected.position.z ,
			duration: 1.5,
		})
		gsap.to(particles.position, {
			x: camera.position.x - currentIntersected.position.x + 0.6,
			z: camera.position.z - 2 - currentIntersected.position.z ,
			duration: 1.5,
		})
	}
	gsap.to(currentIntersected.position, {
		y: currentIntersected.position.y + 0.1,
		duration: 3,
		onStart: function(){
			if(audioStatusActive){
				playearthquakeSound()
			}
		}
	})
	gsap.to(camera.position,{
		y: 3.2,
		z: camera.position.z - 0.8,
		duration: 1.8,
		onComplete: function(){
			closeIcon.classList.remove('delayed')
			particlesMaterial.uniforms.animation.value = true
			if(sizes.width > 1001){
				fragmentShaderOpacityIncrease()
			}

		}
	})
	
}



let zoomCameraY
//zoom
// const zoomIn = document.querySelector('.zoomIn')
// const zoomOut = document.querySelector('.zoomOut')

// zoomIn.addEventListener('click', ()=>{
// 	if(zoomCameraY > 3.5){
// 		gsap.to(camera.position,{
// 			y: zoomCameraY - 2,
// 			duration: 0.8,
// 		})
// 	} else if (zoomCameraY > 3.5 && zoomCameraY < 5.3){
// 		gsap.to(camera.position,{
// 			y: zoomCameraY - 0.5,
// 			duration: 0.8,
// 		})
// 	}
// })

// zoomOut.addEventListener('click', ()=>{
// 	if(zoomCameraY < 8.9){
// 		gsap.to(camera.position,{
// 			y: zoomCameraY + 2,
// 			duration: 0.8,
// 		})
// 	} else if (zoomCameraY > 5.9 && zoomCameraY < 8.9){
// 		gsap.to(camera.position,{
// 			y: zoomCameraY + 0.2,
// 			duration: 0.8,
// 		})
// 	}
// })


let cameraFov = camera.fov;
let newCameraFov;
// console.log('camera.fov', camera.fov)

const zoomInButton = document.querySelector('.zoomIn')
const zoomOutButton = document.querySelector('.zoomOut')
const zoomInFunction = () => {
	
	canvasZoomControlsStopRaycasting()
  const fov = getFov();
  camera.fov = clickZoom(fov, "zoomIn");
  camera.updateProjectionMatrix();
  cameraFov = camera.fov
};

zoomInButton.addEventListener("click",()=>{
	if(hintsActive){
		hintsWrapper.classList.add('hidden')
		window.setTimeout(()=>{
			hintsWrapper.classList.remove('hidden')	
		}, 1000)
	}	
	zoomInFunction()
}
);

const zoomOutFunction = () => {
	canvasZoomControlsStopRaycasting()
  const fov = getFov();
  camera.fov = clickZoom(fov, "zoomOut");
  camera.updateProjectionMatrix();
  cameraFov = camera.fov
};

zoomOutButton.addEventListener("click", ()=>{
	if(hintsActive){
		hintsWrapper.classList.add('hidden')
		window.setTimeout(()=>{
			hintsWrapper.classList.remove('hidden')	
		}, 1000)
	}	
	zoomOutFunction()
	
});

const clickZoom = (value, zoomType) => {
  if (value >= 20 && zoomType === "zoomIn") {
    return value - 5;
  } else if (value < 55 && zoomType === "zoomOut") {
    return value + 5;
  } else {
    return value;
  }
};

const getFov = () => {
  return Math.floor(
    (2 * Math.atan(camera.getFilmHeight() / 2 / camera.getFocalLength()) * 180) / Math.PI );
};


	//slider range
	const rangeZoom = document.querySelector('#zoomRangeInput')
function toucMousehHintsFunctionStart(){
	raycasterActive = false
	hintsWrapper.classList.add('hidden')
	zoomWrapper.classList.add('hidden')
	hintsProgressContainer.classList.add('hidden')
}
function toucMousehHintsFunctionEnd(){
	raycasterActive = false

	hintsProgressContainer.classList.remove('hidden')
	zoomWrapper.classList.remove('hidden')
	hintsWrapper.classList.remove('hidden')
}


	rangeZoom.addEventListener('touchstart',()=>{
		if(hintsActive){
			toucMousehHintsFunctionStart()
		}
	})
	rangeZoom.addEventListener('mousedown',()=>{
		const currentItersectedMaterial = currentIntersected.material.clone()
			currentItersectedMaterial.color = {r:1, g: 1, b: 1}
			currentItersectedMaterial.blending = 1
			currentIntersected.material = currentItersectedMaterial
		raycasterActive = false
		if(hintsActive){
			toucMousehHintsFunctionStart()
		}
	})

	rangeZoom.addEventListener('touchend',()=>{
		if(hintsActive){
			toucMousehHintsFunctionEnd()
		}
	})
	rangeZoom.addEventListener('mouseup',()=>{
		raycasterActive = true
		if(hintsActive){
			toucMousehHintsFunctionEnd()
		}
	})


	
	rangeZoom.addEventListener('input',(e)=>{
		// if(hintsActive){
		// 	return
		// }
		// console.log('e.target.value', e.target.value)
// console.log('camera.position.y', camera.position.y)
		gsap.to(camera.position,{
			y: e.target.value / 4,
			duration: 0.2,
		})

		// if(currentIntersected){
		// 	console.log('merge')
		// 	const currentItersectedMaterial = currentIntersected.material.clone()
		// 	currentItersectedMaterial.color = {r:1, g: 1, b: 1}
		// 	currentItersectedMaterial.blending = 1
		// 	currentIntersected.material = currentItersectedMaterial
		// }
	})
	function canvasZoomControlsStopRaycasting(){

		if(sizes.width < 1001 && currentIntersected){
			raycasterActive = false
		}
	}
	rangeZoom.addEventListener('touchstart',()=>{
		canvasZoomControlsStopRaycasting()
		if(currentIntersected){
			const currentItersectedMaterial = currentIntersected.material.clone()
			currentItersectedMaterial.color = {r:1, g: 1, b: 1}
			currentItersectedMaterial.blending = 1
			currentIntersected.material = currentItersectedMaterial
		}
	})
	
	let grab = false
	let inspectedWithMouse
	//mouse down on country event
	function onMouseTouchDown (){

		grab = true
		currentPositionCamera = camera.position.clone()
		if(currentIntersected.name && currentIntersected.name !== 'Plane'){
			// console.log('currentIntersected', currentIntersected)
			// console.log('currentPositionCamera', currentPositionCamera)
			// console.log('newPositionCamera', newPositionCamera)
			timerInterval = setInterval(()=>{
				// console.log('currentPositionCamera', currentPositionCamera)
				// console.log('newPositionCamera', newPositionCamera)
				
				if(currentPositionCamera.x === newPositionCamera.x || currentPositionCamera.z === newPositionCamera.z){
					// console.log('countrySearcher.style.backgroundPosition', countrySearcher.style.backgroundPosition)
					// console.log('timer', timer)
				countrySearcher.style.backgroundPosition = -30 + timer * 25 + 'rem'

					grab = false

				timer += 0.1;
			if(timer.toFixed(1) == 1.5){
				controls.enablePan = false
				raycasterActive = false
				animationInProcess = true
				// console.log('cameraFov', cameraFov)
				// if(cameraFov > 10 && cameraFov < 40){
				// 	cameraFov = camera.fov * 0.6
				// 	// console.log('woooooooooooooooooorks', woooooooooooooooooorks)

				// } else{
				// 	cameraFov = camera.fov 
				// }
				let counter = 0
				cameraMovement(currentIntersected)
				gsap.to(camera,{
					fov: 55,
					duration: 1.5,
					onUpdate: function(){
						camera.updateProjectionMatrix();
						raycasterActive = false
						counter++;
					}
					

				})

				//country finder by click function				
				countryFinderByClick(currentIntersected)
				// countryStatsInfo.target.scrollTop = 0
				countrySearcherContainer.classList.add('hidden')
				countryStatsWrapper.classList.remove('hidden')
				rangeZoom.classList.add('hidden')
				zoomWrapper.classList.add('hidden')
				raycasterActive = false

				inspectedWithMouse = true

				
				
				
			}
		}
		// console.log('timer', timer.toFixed(1))
		}, 100)
		}
	
	}


	function onMouseTouchUp(){
		grab = false
		clearInterval(timerInterval)
		timer = 0;
		currentPositionCamera = newPositionCamera
		countrySearcher.style.backgroundPosition = '-30rem'
	}

	canvas.addEventListener('mousedown', (e) => {
		// console.log('e', e)
		if(e.which === 1){
			onMouseTouchDown()
		}
	})	
	
		//mouse down on country event
	canvas.addEventListener('mouseup', () => onMouseTouchUp())

	canvas.addEventListener('touchstart', () => {
		
		raycasterActive = false
		currentPositionCamera = camera.position.clone()

	})	
	canvas.addEventListener('touchend', () =>{
		currentPositionCamera = newPositionCamera
		raycasterActive = false

	})

	if(sizes.width < 1001){
		raycasterActive = false
	}
	let animationInProcess
	canvas.addEventListener('click',()=>{

		if(!animationInProcess && sizes.width < 1001){
			if(currentPositionCamera.x === newPositionCamera.x || currentPositionCamera.z === newPositionCamera.z){
				raycasterActive = true
			}
		}
	})

	// 	canvas.addEventListener('touchstart', () => {
	// 	currentPositionCamera = camera.position.clone()
	// 	raycasterActive = false


	// })	
	// canvas.addEventListener('touchend', () =>{
	// 	if(currentPositionCamera.x === newPositionCamera.x || currentPositionCamera.z === newPositionCamera.z){
	// 		raycasterActive = true
	// 		currentPositionCamera = newPositionCamera
	// 	}
	// })


	// console.log('first', first)
	const controlsVoid = document.querySelector('.void')
	const voidContainerTheSecond = document.querySelector('.void-container-the-second')
	locationButton.addEventListener('click', () => {
		if(hintsActive){
			return;
		}
		if(currentIntersected.name !== 'Plane'){
			controls.enablePan = false
			animationInProcess = true
			controlsVoid.classList.remove('hidden')
			zoomWrapper.classList.add('noControls')
			raycasterActive = false
		// zoomContainer.classList.add('hidden')

			// console.log('currentIntersected', currentIntersected)
		// 	if(cameraFov > 10 && cameraFov < 35){
		// 	cameraFov = camera.fov * 1.6
		// } else{
		// 	cameraFov = camera.fov
		// }
		cameraMovement(currentIntersected)
		gsap.to(camera,{
			fov: 55,
			duration: 2,
			onUpdate: function(){
				camera.updateProjectionMatrix();
				
			}
		})
		countryFinderByClick(currentIntersected)
		countrySearcherContainer.classList.add('hidden')
		countryStatsWrapper.classList.remove('hidden')
		rangeZoom.classList.add('hidden')


		// 	timerInterval = setInterval(()=>{
				
		// 		if(currentPositionCamera.x === newPositionCamera.x || currentPositionCamera.z === newPositionCamera.z){
		// 			console.log('countrySearcher.style.backgroundPosition', countrySearcher.style.backgroundPosition)
		// 			console.log('timer', timer)

		// 		countrySearcher.style.backgroundPosition = -30 + timer * 17.5 + 'rem'

		// 			grab = false

		// 		timer += 0.1;
		// 	if(timer.toFixed(1) == 2.0){

		// 		// console.log('cameraFov', cameraFov)

		// 		//country finder by click function				

				
		// 		clearInterval(timerInterval)
		// 		timer = 0;
		// 		currentPositionCamera = newPositionCamera
		// 		countrySearcher.style.backgroundPosition = '-30rem'
		// 		// raycasterActive = false
		// 	}
		// }
		// console.log('timer', timer.toFixed(1))
		// }, 100)
		}
	})

let allVisibleCountriesAfterInput = []
let submitedValueInCountrySearch
function countrySearcherForm(e){
	controls.enablePan = false
	raycasterActive = false
	animationInProcess = true
	controlsVoid.classList.remove('hidden')
	let targetCountryName;
	let targetCountryMesh
	const allAvailableCountries = gltfScene.children
	if(e){
		countrySearcherResultsContainer.classList.add('hidden')
		// console.log('countryCardTemplate', countryCardTemplate.children)
		// console.log('e', e)
		if(e.target.childNodes[0].id){
			targetCountryName = e.target.childNodes[0].id
		} else{
			targetCountryName = e.target.childNodes[0].textContent

		}
			// console.log('targetCountryName', targetCountryName)

		const targetCountryAbreviation = countryByAbreviation.find(country => country.country === targetCountryName).abbreviation
		// console.log('targetCountryAbreviation', targetCountryAbreviation)
		// console.log('allAvailableCountries', allAvailableCountries)
		targetCountryMesh	= allAvailableCountries.find((country)=>{
			// console.log('country.name.slice(0,2).toLowerCase()', country.name.slice(0,2).toLowerCase())
			// console.log('targetCountryAbreviation.toLowerCase()', targetCountryAbreviation.toLowerCase())
			const countryMesh = country.name.slice(0,2).toLowerCase() === targetCountryAbreviation.toLowerCase()
			searchedCountryBool = countryMesh
			// console.log('countryMesh', countryMesh)
			return countryMesh;
		})
	} else if(!e && allVisibleCountriesAfterInput.length === 1 || allVisibleCountriesAfterInput[0].id.toLowerCase() === submitedValueInCountrySearch.toLowerCase()){
			countrySearcherResultsContainer.classList.add('hidden')
			targetCountryName = allVisibleCountriesAfterInput[0].id
			// console.log('targetCountryName', targetCountryName)
			const targetCountryAbreviation = countryByAbreviation.find(country => country.country === targetCountryName).abbreviation
			
			
			targetCountryMesh	= allAvailableCountries.find((country)=>{
				
				const countryMesh = country.name.slice(0,2).toLowerCase() === targetCountryAbreviation.toLowerCase()
				searchedCountryBool = countryMesh
				// console.log('countryMesh', countryMesh)
				allVisibleCountriesAfterInput = []
				return countryMesh;
			})
	} else {
		// countriesByInput.forEach(country => {
			// console.log('countriesByInput', inputContainer.value)
			// 	const isVisible = country.name.toLowerCase().includes('a')
			// 	country.element.classList.toggle("hidden", false)
			// })
		console.log('break')
		countrySearcher.focus()
		allVisibleCountriesAfterInput = []
		return;
	}
	
	if(targetCountryMesh){
		cameraMovement(targetCountryMesh)
		//country finder by click function				
		countryFinderByClick(targetCountryMesh)
		countrySearcherContainer.classList.add('hidden')
		rangeZoom.classList.add('hidden')
		if(sizes.width > 1001){
			countryStatsWrapper.classList.remove('hidden')
		}
		zoomWrapper.classList.add('hidden')
	}
	// console.log('targetCountryMesh', targetCountryMesh)
		searchedCountry = targetCountryMesh
		if(searchedCountry){
			const searchedCountryMaterial = searchedCountry.material.clone()
			searchedCountryMaterial.color = {r:0.145, g: 0.178, b: 0.199}
			searchedCountryMaterial.blending = 1
			searchedCountry.material = searchedCountryMaterial
		}else{
			countrySearcher.focus()
			return;
		}
	
	
		// console.log('searchedCountryMaterial', searchedCountryMaterial)
		// console.log('searchedCountry', searchedCountry)
	// if(cameraFov > 10 && cameraFov < 35){
	// 		cameraFov = camera.fov * 1.6
	// 	} else{
	// 		cameraFov = camera.fov
	// 	}
		gsap.to(camera,{
			fov: 55,
			duration: 1.5,
			onUpdate: function(){
				camera.updateProjectionMatrix();
			},
			onComplete: function(){
				if(sizes.width < 1001){
					countryStatsWrapper.classList.remove('hidden')
				}
			}
		})
	
		countrySearcher.value = targetCountryName
		currentIntersected = targetCountryMesh
}


	let searchedCountryBool;
	var searchedCountry;
	//searchbar
	const countryHtmlList = document.querySelectorAll('ul li')
	// console.log('countryHtmlList', countryHtmlList)
	countryHtmlList.forEach((country)=>{
		country.addEventListener('click', (e)=>{
			
			countrySearcherForm(e)
		})
	})

	//form submit
	function formSubmitFunction(){

		// checking all Visible Countries after input
		for(let i = 1; i < countrySearcherResultsContainer.children.length; i++){
			const DOMcountry = countrySearcherResultsContainer.children[i]
			if(!DOMcountry.classList.contains('hidden')){
				// console.log('DOMcountry', DOMcountry)
				allVisibleCountriesAfterInput.push(DOMcountry)
			}
		}
		// console.log('countrySearcher.value', countrySearcher.value)
		countrySearcherForm(undefined)
	}
	const inputContainer = document.querySelector('.input-container')
		inputContainer.addEventListener('submit', (e)=>{
			submitedValueInCountrySearch = countrySearcher.value
			console.log('submitedValueInCountrySearch', submitedValueInCountrySearch)
		e.preventDefault()
		formSubmitFunction()	
		
	})
		// search icon event
	const searchIcon = document.querySelector('svg.search')
	searchIcon.addEventListener('click',()=>{
		formSubmitFunction()			
	})

	let countrySearcherResultsContainerArray = countrySearcherResultsContainer.children
	// console.log('countrySearcherResultsContainerArray', countrySearcherResultsContainerArray)
	// console.log('countrySearcherResultsContainer', countrySearcherResultsContainer.children)
	



//resize screen
window.addEventListener('resize', () =>
{
    // Update sizes
	
	sizes.width = window.innerWidth
	sizes.height = window.innerHeight

	//  if(window.innerWidth < 801){
	// 	 sizes.height = window.innerHeight * 0.95
	// 	}else{
	// 		sizes.height = window.innerHeight
	// 	}

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

	 //update fireflies
	 particlesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)

	 if(!hintsActive){
		 if(sizes.width > 1001){
			raycasterActive = true
		 }
		checkingOrientation()
	}

})

/**
 * Fullscreen
 */

const fullscreenPc = document.querySelector('.fullscreen.forPc')
const fullscreenMob = document.querySelector('.fullscreen.forMob')

	fullscreenPc.addEventListener('click',()=>{
		
		document.documentElement.requestFullscreen()
	})

	fullscreenMob.addEventListener('click',()=>{
		if (document.fullscreenElement) {
			document.exitFullscreen();
			fullscreenMob.classList.remove('fullscrened')
    } else {
			document.documentElement.requestFullscreen();
			fullscreenMob.classList.add('fullscrened')
    }
	})

const exitFullscreen = document.querySelector('.fullscreen-exit')
	exitFullscreen.addEventListener('click',()=>{
	
		document.exitFullscreen()
	})
// window.addEventListener('dblclick', () =>
// {
//     const fullscreenElement = document.fullscreenElement || document.webkitFullscreenElement

//     if(!fullscreenElement)
//     {
//         if(canvas.requestFullscreen)
//         {
//             canvas.requestFullscreen()
//         }
//         else if(canvas.webkitRequestFullscreen)
//         {
//             canvas.webkitRequestFullscreen()
//         }
//     }
//     else
//     {
//         if(document.exitFullscreen)
//         {
//             document.exitFullscreen()
//         }
//         else if(document.webkitExitFullscreen)
//         {
//             document.webkitExitFullscreen()
//         }
//     }
// })

// Controls
const controls = new MapControls(camera, canvas)
// controls.enableDamping = true
controls.maxDistance = 9
controls.minDistance =  3.4
controls.zoomSpeed = 0.6
controls.userRotate = false
controls.rotateSpeed = 0
controls.panSpeed = 0.8
controls.enableZoom = false
controls.maxPolarAngle = Math.PI / 3

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding


//Axe helper
// const axesHelper = new THREE.AxesHelper( 5 );
// scene.add( axesHelper );

// const se = new THREE.Vector2()
// se.x = 0.49
// se.y = -0.12
//cursor design
// const cursor = document.querySelector('.cursor');
// const cursorinner = document.querySelector('.cursor2');

// document.addEventListener('mousemove', function(e){
//   const x = e.clientX;
//   const y = e.clientY;
//   cursor.style.transform = `translate3d(calc(${x}px - 50%), calc(${y}px - 50%), 0)`
//   cursorinner.style.transform = `translate3d(calc(${x}px - 50%), calc(${y}px - 50%), 0)`
// });

// document.addEventListener('mousemove', function(e){
//   const x = e.clientX;
//   const y = e.clientY;
//   cursorinner.style.left = x + 'px';
//   cursorinner.style.top = y + 'px';
// });
// canvas.oncontextmenu = function(e) { e.preventDefault(); e.stopPropagation(); }

//improving ambience with particles
//geometry
const particlesGeometry = new THREE.BufferGeometry()
let particlesCount
if(sizes.width > 1001){
	particlesCount = 1800
} else{
	particlesCount = 800
}

const positionArray = new Float32Array(particlesCount * 3)
const scaleArray = new Float32Array(particlesCount)

if(sizes.width > 1001){
	for(let i = 0; i < particlesCount; i++){
		positionArray[i * 3 + 0] = (Math.random() - 0.5) * 16
		positionArray[i * 3 + 1] = Math.random() 
		positionArray[i * 3 + 2] = (Math.random() - 0.5) * 10
		scaleArray[i] = Math.random()
	}
} else{
	for(let i = 0; i < particlesCount; i++){
		positionArray[i * 3 + 0] = (Math.random() - 0.5) * 10
		positionArray[i * 3 + 1] = Math.random() 
		positionArray[i * 3 + 2] = (Math.random() - 0.5) * 10
		scaleArray[i] = Math.random()
	}
}

particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
particlesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))

//material
const particlesMaterial = new THREE.ShaderMaterial({
	blending: THREE.AdditiveBlending,
	depthWrite: false,
	uniforms:{
		animation: {value: true},
		uOpacity: {value: 1 },

		uTime:{value: 0},
		uPixelRatio: {value: Math.min(window.devicePixelRatio, 2)},
		uSize:{value: 35},
	},
	vertexShader: particlesVertexShader,
	fragmentShader: particlesFragmentShader,
	transparent: true, 
})
//points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
particles.position.y = 1.6
scene.add(particles)
// console.log('particles', particles)

// camera.position.y = 3.5

const landscapePopupWrapper = document.querySelector('.landscape-popup_wrapper')
const checkingOrientation = ()=>{
	if(formIsActive){
		return;
	}else{
		if(window.innerWidth < 1001 && window.innerHeight < 600 && window.innerHeight < window.innerWidth){
			countrySearcher.classList.add('hidden')
			zoomWrapper.classList.add('hidden')
			zoomRangeWrapper.classList.add('hidden')
			landscapePopupWrapper.classList.remove('hidden')
			isLandscape = true
		} else{
			countrySearcher.classList.remove('hidden')
			zoomWrapper.classList.remove('hidden')
			zoomRangeWrapper.classList.remove('hidden')
			landscapePopupWrapper.classList.add('hidden')
		}
	}
}
checkingOrientation()
const locationButtonSvg = document.querySelector('.locationButton svg')
/**
 * Animate
 */
const clock = new THREE.Clock()
const tick = () =>
{
	const elapsedTime = clock.getElapsedTime()

	particlesMaterial.uniforms.uTime.value = elapsedTime
// console.log('elapsedTime', elapsedTime)
	// Update controls
	controls.update()
	rangeZoom.value = camera.position.y * 4
	
// console.log('currentIntersected', currentIntersected)
	// update the camera position for pan
	newPositionCamera = camera.position
// console.log('newPositionCamera', newPositionCamera)
	 //cast ray
	// raycaster.setFromCamera(initialRaycast, camera)
		 raycaster.setFromCamera(mouse, camera)
		 if(loadingStatus === true){		

			 if(raycasterActive && !animationInProcess){

		const intersects = raycaster.intersectObjects(modelsToTest)
		
		//hovering country
		if(currentPositionCamera){
			// console.log('currentPositionCamera', currentPositionCamera)
			// console.log('newPositionCamera', newPositionCamera)
		if(currentPositionCamera.x === newPositionCamera.x || currentPositionCamera.z === newPositionCamera.z){
			// console.log('yes')
			// console.log('micky mouse' )
			if(intersects[0]){
			const newMaterial = intersects[0].object.material.clone()
			newMaterial.color = {r:0.145, g: 0.178, b:0.199}
			newMaterial.blending = 1
			newMaterial.transparent = true

			currentIntersected = intersects[0].object
			if(intersects[0].object.name !== 'Plane' ){
				intersects[0].object.material = newMaterial
				canvas.style.cursor = 'pointer'
				const countryAbv = intersects[0].object.name.slice(0,2)
				const country = countryByAbreviation.find(country => country.abbreviation === countryAbv)
				if(country){
					countrySearcher.value = country.country 
					// console.log('countrySearcher', countrySearcher)
				} else {
					countrySearcher.value = 'No information'
				}
			}else{
				canvas.style.cursor = 'default'
			}
			
		}
	}
}
			// countrySearcher

			for(const model of modelsToTest){
				// if(intersects[0]){ temporary solution of undefind when leaving the target
					// if(intersects[0]){

						if(intersects[0] && intersects[0].object !== model){
							const initialMaterial = model.material
							initialMaterial.color = {r:1, g: 1, b:1}
							model.material = initialMaterial
						}
					// }
			}
		}
		
			
	}
	//cool rotation on scroling
	const screenPosition= new Vector3(camera.position.x, 2, camera.position.z)
	const cameraPosition = camera.position
	let distance = screenPosition.distanceTo(cameraPosition)
	camera.rotation.x /= -0.12765 * distance + 1.902
	if(currentCountry){
		currentCountry.material.color = {r:0.145, g: 0.178, b:0.199}
		currentCountry.material.blending = 1
		currentCountry.material.transparent = true
	} 
	if(currentIntersected && currentIntersected.name !== 'Plane' ){
		locationButtonSvg.classList.add('currentIntersected')
		// console.log('yes')
	}else{
		// console.log('no')
		locationButtonSvg.classList.remove('currentIntersected')
	}

	zoomCameraY = camera.position.y
	// console.log('zoomCameraY', zoomCameraY)

	if(searchedCountryBool){
		// console.log('searchedCountry', searchedCountry)
				// console.log('country', country)
				// searchedCountry.material.transparent = true
				
				// const searchedCountryMaterial = searchedCountry.material  
				// searchedCountryMaterial.color = {r:0.145, g: 0.178, b: 0.199}
				// searchedCountryMaterial.blending = 1
				// searchedCountryMaterial.needsUpdate()
				// modelsToTest.forEach((country)=>{
				// 	// console.log('country', country)c
				// // console.log('searchedCountry', searchedCountry)
				// 	const countryMaterial = country.material
				// 	if(country.name !== searchedCountry.name && country.name !== 'Plane'){
				// 		countryMaterial.color = {r:1, g: 1, b:1}
				// 		country.material = countryMaterial
				// 	} 
					// else{
					// 	searchedCountry.material.blending = 1
					// 	searchedCountry.material.color = {r:0.145, g: 0.178, b:0.199}
					// }

			// console.log('country.name', country.name)
			// console.log('searchedCountry.name', searchedCountry.name)
			// if(country.name === searchedCountry.name){

			// 	countryMaterial.blending = 1
			// 	countryMaterial.color = {r:0.145, g: 0.178, b:0.199}
			// 	country.material = countryMaterial

				
			// 	// countryMaterial.transparent = true
			// 	// console.log('searchedCountry', searchedCountry)
				
			// 	// country.material.color = {r:0.144, g: 0.178, b:0.199}
			// 	// country.material = countryMaterial
			// 	// country.material.blending = 1
			// 	// country.material.transparent = true
			// 	// console.log('country', country)
			// }
		// })

	}
	// console.log('modelsToTest', modelsToTest)

	if(grab){
		canvas.style.cursor = 'grab'
	}
	// console.log(sizes.height)
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick();
